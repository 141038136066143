import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import SEO from "../components/seo";

function ContactPage(props) {
  return (
    <Layout>
      <SEO keywords={[`Contact us`]} title="Contact us" />
      {props.location.search === "?success" && (
        <div className="bg-green-100 text-green-900 text-center p-3">
          Thank you for your message!
          <br />
          We will get back to you as soon as we can.
        </div>
      )}
      <section className="section bg-white bg-gray-200 py-40 relative">
        <div
          className="bg-image"
          style={{
            backgroundImage: `url(${require("../images/world.jpg")})`,
            backgroundPosition: "center 60%",
          }}
        ></div>
        <div
          className="bg-overlay"
          style={{
            background:
              "linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,0.7)",
          }}
        ></div>

        <div className="max-w-xl text-center mx-auto text-left px-3 relative">
          <h2 className="text-white">Contact us</h2>
          <p className="text-white text-xl">
            Please fill out the contact form below and we will get back to you
            as soon as we can.
          </p>
        </div>
      </section>
      <section className="py-16">
        <div className="container">
          <div className="max-w-xl mx-auto">
            <h3>How can we help you?</h3>
            <form
              action="/contact?success"
              name="contact"
              method="POST"
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="contact" />
              <div>
                <label>Your Name</label>
                <input name="name" type="text" autoFocus required />
              </div>
              <div>
                <label>Your E-mail</label>
                <input name="email" type="email" required />
              </div>
              <div>
                <label>Message</label>
                <textarea name="message" rows="5" required></textarea>
              </div>
              <div>
                <input
                  name="submit"
                  type="submit"
                  value="Send message →"
                  className="btn bg-gray-800 text-white"
                />
              </div>
            </form>
          </div>
          {/* <div className="lg:w-1/2 bg-gray-100">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9685.670659905436!2d1.2863913!3d52.6343668!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc5dbf7fec6819657!2sHappy%20Ohm!5e0!3m2!1sen!2sjp!4v1589361546676!5m2!1sen!2sjp"
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullscreen=""
                ariaHidden="false"
                tabIndex="0"
              ></iframe>
            </div> */}
        </div>
      </section>
    </Layout>
  );
}

ContactPage.propTypes = {
  location: PropTypes.object,
};

export default ContactPage;
